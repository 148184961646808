import * as serializers from '@/constants/serializer';
import { ENUM, LIST } from '@/constants/viewTypes';
import { PATH_POINTERS } from '@/constants/builder';

import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';
import * as dictionaryTypes from './dictionaryTypes';

const dependentProperties = {
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  ROOM: 'room',
  USAGES: 'usages',
  CLIENT_TYPE: 'clientType',
  SALES_CONTRACT_DATE: 'salesContractDate',
};

/**
 * @type {Array<import('./schema').default>}
 */

export const schemaPersonalInfo = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'client.first_name',
        prop: 'firstName',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.last_name',
        prop: 'lastName',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'client.passport',
        prop: 'passport',
        size: 2,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.id',
        prop: 'idNumber',
        size: 2,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.COUNTRY,
        label: 'client.country',
        prop: 'country',
        size: 2,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.phone',
        prop: 'phone',
        rules: [rules.PHONE],
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'client.email',
    prop: 'email',
    rules: [rules.REQUIRED, rules.EMAIL],
  },
  {
    type: schemaItemTypes.BOOLEAN,
    prop: 'isSendPasswordWhenCreating',
    label: 'client.change_status_checkbox_send_password_now',
  },
  {
    label: 'client.passport',
    prop: 'passportScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    accept: 'image/*',
    editable: true,
    onlyOne: true,
    size: 6,
  },
  {
    label: 'client.id',
    prop: 'idScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    accept: 'image/*',
    editable: true,
    onlyOne: true,
    size: 6,
  },
];

export const schemaAddress = [
  {
    type: schemaItemTypes.LIST,
    prop: dependentProperties.USAGES,
    uniqueKey: 'id',
    viewType: LIST.counter,
    addButtonText: 'button.add_address',
    label: 'client.address',
    min: 1,
    max: 10,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'client.project',
            type: schemaItemTypes.PROJECT,
            prop: dependentProperties.PROJECT,
            rules: [rules.REQUIRED],
            onUpdate: [
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING],
              },
            ],
          },
          {
            type: schemaItemTypes.BUILDING,
            label: 'client.building',
            prop: dependentProperties.BUILDING,
            rules: [rules.REQUIRED],
            payload: [
              {
                param: 'projectId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT, 'id'],
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT],
                from: 'project',
              },
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.UNIT],
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.UNIT,
            label: 'client.unit',
            prop: dependentProperties.UNIT,
            rules: [rules.REQUIRED],
            payload: [
              {
                param: 'projectId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT, 'id'],
              },
              {
                param: 'buildingId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING, 'id'],
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING],
                from: 'building',
              },
            ],
          },
          {
            type: schemaItemTypes.ROOM,
            label: 'label.room',
            prop: dependentProperties.ROOM,
            access: ['rooms'],
            payload: [
              {
                param: 'unitId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.UNIT, 'id'],
              },
              {
                param: 'buildingId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING, 'id'],
              },
              {
                param: 'projectId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT, 'id'],
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.UNIT],
                from: 'unit',
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        prop: dependentProperties.CLIENT_TYPE,
        dictionary: dictionaryTypes.CLIENT_TYPES,
        defaultValue: 'owner',
        onUpdate: [
          {
            type: onUpdateEffects.RESET,
            to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.SALES_CONTRACT_DATE],
          },
        ],
      },
      {
        type: schemaItemTypes.DATE,
        rules: [rules.REQUIRED],
        prop: dependentProperties.SALES_CONTRACT_DATE,
        tooltip: {
          text: 'unit.sales_contract_date_tooltip',
          icon: 'mdi-information',
        },
        label: {
          from: ['multiple'],
          options: [
            { key: 'client.sales_contract_date', value: false },
            { key: 'client.rent', value: true },
          ],
        },
        payload: [
          {
            param: 'multiple',
            from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.CLIENT_TYPE],
            serializer: serializers.IS_TENANT,
          },
        ],
      },
    ],
  },
];

export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'client.first_name',
        prop: 'firstName',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.last_name',
        prop: 'lastName',
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'client.personal_information',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'client.passport',
            prop: 'passport',
            size: 2,
          },
          {
            type: schemaItemTypes.COUNTRY,
            label: 'client.country',
            prop: 'country',
            size: 2,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'client.phone',
            prop: 'phone',
            rules: [rules.PHONE],
          },
          {
            type: schemaItemTypes.STRING,
            label: 'client.email',
            prop: 'email',
            rules: [rules.REQUIRED, rules.EMAIL],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.LIST,
    prop: dependentProperties.USAGES,
    uniqueKey: 'id',
    viewType: LIST.counter,
    addButtonText: 'button.add_address',
    label: 'client.address',
    min: 1,
    max: 10,
    children: [
      {
        label: 'client.project',
        type: schemaItemTypes.PROJECT,
        prop: dependentProperties.PROJECT,
        rules: [rules.REQUIRED],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.BUILDING,
            label: 'client.building',
            prop: dependentProperties.BUILDING,
            rules: [rules.REQUIRED],
            payload: [
              {
                param: 'projectId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT, 'id'],
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT],
                from: 'project',
              },
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.UNIT],
              },
            ],
          },
          {
            type: schemaItemTypes.UNIT,
            label: 'client.unit',
            prop: dependentProperties.UNIT,
            rules: [rules.REQUIRED],
            payload: [
              {
                param: 'projectId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.PROJECT, 'id'],
              },
              {
                param: 'buildingId',
                from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING, 'id'],
              },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.BUILDING],
                from: 'building',
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        prop: dependentProperties.CLIENT_TYPE,
        dictionary: dictionaryTypes.CLIENT_TYPES,
        defaultValue: 'owner',
        onUpdate: [
          {
            type: onUpdateEffects.RESET,
            to: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.SALES_CONTRACT_DATE],
          },
        ],
      },
      {
        type: schemaItemTypes.DATE,
        rules: [rules.REQUIRED],
        prop: dependentProperties.SALES_CONTRACT_DATE,
        tooltip: {
          text: 'unit.sales_contract_date_tooltip',
          icon: 'mdi-information',
        },
        label: {
          from: ['multiple'],
          options: [
            { key: 'client.sales_contract_date', value: false },
            { key: 'client.rent', value: true },
          ],
        },
        payload: [
          {
            param: 'multiple',
            from: [dependentProperties.USAGES, PATH_POINTERS.currentIndex, dependentProperties.CLIENT_TYPE],
            serializer: serializers.IS_TENANT,
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.BOOLEAN,
    prop: 'isSendPasswordWhenCreating',
    label: 'client.change_status_checkbox_send_password_now',
  },
];

export const isEditSchema = schema.filter(item => item.type !== schemaItemTypes.BOOLEAN);

export { schema as default };
